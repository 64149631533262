<template>
  <div id="app">
    <div class="index">
      <div id="main-1">
        <!-- Header -->
        <header id="header">
          <h1>DAVENUS DANCE</h1>
          <div class="description" v-if="langcode == 'en' ">
            Our children's dance classes are open for enrolment and trial classes.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            我們的兒童舞蹈班招生中，歡迎報名及試堂。
          </div>
          <ul class="icons">
            <li>{{ $t('Address') }}: {{ $t('Unit A, 2nd Floor, No. 140 Castle Peak Road, Yuen Long, Hong Kong')}}</li>
            <li>{{ $t('Tel') }}: 852-6465 5755</li>
          </ul>
          <a href="#" @click="viewAboutUs" style="margin-right: 10px">
            {{ $t('Company Overview')}}
          </a>
          <a href="#" @click="viewService">
            {{ $t('Our Service')}}
          </a>
        </header>

        <!-- Footer -->
          <footer id="footer">
            <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
            <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
            <ul class="copyright">
              <li>&copy;  DAVENUS DANCE COMPANY LIMITED.</li>
            </ul>
            <VueBotUI
            :messages="data"
            @msg-send="messageSendHandler"
            />
          </footer>
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
    <div class="about-us">
      <div id="main-2">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode == 'en' ">Company Overview</h1>
          <h1 v-else>公司概要</h1>
          <div class="description" v-if="langcode == 'en' ">
            We specialise in developing children's interest in dance from an early age, training their sense of rhythm and body coordination.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            我們專業培訓培養小朋友從小對舞蹈產生興趣 訓練他們對音樂節奏感，訓練身體協調性。
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page')}}
          </a>
        </header>
        <VueBotUI
            :messages="data"
            @msg-send="messageSendHandler"
            />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/02.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
    <div class="our-service">
      <div id="main-3">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode == 'en' ">Our Service</h1>
          <h1 v-else>我們的服務</h1>
          <div class="description" v-if="langcode == 'en' ">
            At the school, children will have the opportunity to experience a variety of dance styles. Our team of professionally trained teachers have many years of experience in teaching dance and are able to bring high quality, fun and creative dance instruction to primary school children.
            We believe that by leading children to explore a variety of artistic expressions, we can develop their artistic sense and confidence, and help them to discover their unknown talents.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            在學校裡，小朋友將有機會體驗各種舞蹈類型。我們的教師團隊皆經專業訓練，並擁有多年教育經驗，能夠為小學生帶來高品質、有趣及創意的舞蹈教學。
            我們深信，在領導小朋友探索各種美術表現之際，可以培養出孩子們的藝術感與自信，並助他們發掘未知的才能。
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page')}}
          </a>
        </header>
        <VueBotUI
            :messages="data"
            @msg-send="messageSendHandler"
            />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/03.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted(){
    const langcode = Cookies.get('langcode');
    if(langcode){
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
    //this.loadWebsiteContent();
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [
        {
          agent: 'bot',
          type: 'button',
          text: 'Hello, welcome to DAVENUS DANCE. How can I help you? ',
          disableInput: false,
        }
      ],
      replyTexts: [
        {
          'question': 'business_hour',
          'answer': "Our Business Hour is 10:00am - 17:00pm.",
        },
        {
        'question': 'service_target',
          'answer': "Our team of professionally trained teachers have many years of experience in teaching dance and are able to bring high quality, fun and creative dance instruction to children (3-12 years old).",
        },
        {
          'question': 'service_location',
          'answer': "Currently, we only provide service at Yuen Long.",
        },
        {
          'question': 'place_order',
          'answer': 'If you have any questions about us, please call 852-64655755 for more information',
        },
      ],
      name: '',
      descEn: '',
      descZh: '',
      contact: '',
    }
  },
  methods:{
    viewAboutUs(){
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'block';
    },
    viewService(){
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.our-service');
      about.style.display = 'block';
    },
    backIndex(){
      const index = document.querySelector('.index');
      index.style.display = 'block';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const service = document.querySelector('.our-service');
      service.style.display = 'none';
    },
    async loadConfig(){
      return new Promise(async(resolve, reject) => {
        const baseApiUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseApiUrl}/website/content`;
        const fetchResult = await fetch(apiUrl, {
          method: 'GET',
          headers: {
           'Content-type': 'application/json; charset=UTF-8'
          },
        });

        const status = await fetchResult.status;

        //If cannot connect with API server
        if(status !== 200 && status !== 401){
          const err = 'Cannot load system config. Maybe there are some network problem?';
          reject(err);
          return;
        }

        //If user do not login or no permission
        if(status === 401){
          const err = 'Sorry, You don\'t had permission to access it.';
          reject(err);
          return;
        }

        const resultJson = await fetchResult.json();

        resolve(resultJson);
      });
    },
    async loadWebsiteContent(){
      try{
        let websiteContent = await this.loadConfig();

        this.name = websiteContent['website_name'];
        this.descZh = websiteContent['website_introduce_zh'];
        this.descEn = websiteContent['website_introduce_en'];
        this.contact = websiteContent['company_contact'];

      }catch(err){
        console.log(err);
      }
    },
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction){
      const question = userAction['value'];
      let answer = this.replyTexts.find(function(item){
        return item.question === question
      });

      const userQuestion = {
        agent: 'user',
        type: 'text',
        text: userAction['text'],
        disableInput: false,
      }
      this.data.push(userQuestion);

      if(answer){
        answer = answer['answer'];
        const reply = {
          agent: 'bot',
          type: 'text',
          text: answer,
          disableInput: false,
        }
        this.data.push(reply);
      }else{
        const reply = {
          agent: 'bot',
          type: 'button',
          text: 'These answer maybe able to help you:',
          disableInput: false,
          options: [
            {
              text: 'Business Hour?',
              value: 'business_hour',
              action: 'postback' // Request to API
            },
            {
              text: 'Service Target?',
              value: 'service_target',
              action: 'postback',
            },
            {
              text: 'Service Location?',
              value: 'service_location',
              action: 'postback',
            },
            {
              text: 'Contact Method?',
              value: 'place_order',
              action: 'postback' // Request to API
            },
          ],
        }
        this.data.push(reply);
      }
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
   langcode: state => state.langcode,
 }),
}
</script>

<style lang="scss">
h1{
  text-align: left;
}
body.is-preload-1 #main{
  opacity: 1;
  right: 0;
}
.qkb-board{
  padding-bottom: 30px;
}
#viewer .slide .image{
  @media screen and (min-width: 1300px){
    background-size: 100%;
  }
}
.about-us, .our-service{
  display: none;
}
.partner{
  margin-top: 50px;
  .logos{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  img{
    display: block;
    width: 100%;
    max-width: 100px;
    margin-right: 10px;
  }
}
.description{
  text-align: left;
  margin-bottom: 20px;
}
</style>